<template>
	<div class="advertisement">  
        <!-- header组件     -->
		<vheader
        :navId=navId>
		</vheader>
        <!-- header组件     -->
		<!-- banner组件 -->
		<banner 
		:navName="navName"
		:navUrl="navUrl"
		></banner>
		<!-- banner组件 -->
        <div class="box05">
			<div class="title05">
				<h1>ADVERTISING</h1>
				<h2>广告展示</h2>
			</div>
			<div class="list05">
				<img v-for="item in box05List" :key="item.id" :src="item.imgUrl" alt="广告展示">
			</div>
		</div>
        <vfooter></vfooter>
		<GoTop/>
    </div>
</template>

<script>
import banner from '../../components/banner.vue'
import vheader from '../../components/vheader.vue'
import vfooter from '../../components/vfooter.vue'
import GoTop from '@/components/GoTop.vue'
export default { 
	name: 'advertisement',
	data () {
		return{
			navId:4,
			navName:'广告展示',
			navUrl:'advertisement',        
			box05List:[
				{imgUrl:require('../../assets/img/index/box05_01.jpg')},
				{imgUrl:require('../../assets/img/index/box05_02.jpg')},
				{imgUrl:require('../../assets/img/index/box05_03.jpg')},
				{imgUrl:require('../../assets/img/index/box05_04.jpg')},
				{imgUrl:require('../../assets/img/index/box05_05.jpg')},
				{imgUrl:require('../../assets/img/index/box05_06.jpg')},
				{imgUrl:require('../../assets/img/index/box05_07.jpg')},
				{imgUrl:require('../../assets/img/index/box05_08.jpg')},
				{imgUrl:require('../../assets/img/index/box05_09.jpg')},
			],
        }
	},
	props: {
	},
	methods:{
		
	},
	components:{
		banner,
		vheader,
		vfooter,
		GoTop
	},
}
</script>

<style scoped lang="less">
// pc端样式
@media screen and (min-width:750px){
    .box05{				
        width: 1200Px;
        margin: AUTO;
        padding: 37Px 0 65Px;	
        .title05{
            :first-child{
                font-size: 42Px;
                color: #ddd;
                text-align: center;
            }
            :nth-child(2){
                width: 89Px;
                margin:0 auto 30Px;
                font-size: 22Px;
                padding-bottom: 13Px;
                color: #222;
                border-bottom: 1Px solid #093C6C;
            }
        }
        .list05{
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            img{
                width:390Px;
                height: 266Px;
                margin-bottom: 13Px;
                cursor: pointer;
				display: block;
                transition: all 0.3s;
				border: 1Px solid #ccc;
                &:hover{
					box-shadow: 0 0 3px #666;
					transform: scale(1.2);
                }
            }
        }
    }
}

// 移动端样式
@media screen and (max-width:750px){
        .box05{				
			width: 95%;
			margin: AUTO;
			padding: 20px 0 30px;	
			.title05{
				width: 95%;
				:first-child{
					font-size: 25px;
					color: #ddd;
					text-align: center;
				}
				:nth-child(2){
					width: 65px;
					font-size: 16px;
					color: #222;
					margin:0 auto 12px;
					padding-bottom: 5px;
					border-bottom: 1px solid #093C6C;
				}
			}
			.list05{
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				img{
					width: 49%;
					height: 123px;
					margin-top: 7px;
					cursor: pointer;
					transition: all 0.2s;
					&:hover{
						opacity: 0.8;
					}
					&:last-child{
						display: none;
					}
				}
			}
		}
}
</style>
